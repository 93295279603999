import { genralstate } from '../gvariables';

const GenralReducer = (state = genralstate, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case 'NAVBAR_SET':
            state = {
                ...state,
                sidebarShow: action.sidebarShow
            }
            break;
        case 'SET_USERDATA':
            state = {
                ...state,
                token: action.data.token,
                profile: action.data.profile
            }
            break;
        case 'SET_SEARCHCOMPANY':
            state = {
                ...state,
                searchCompany: action.searchValue
            }
            break
        case 'SET_CONNECTIONS':
            state = {
                ...state,
                connections: action.connections,
            }
            break;
        case 'SET_NEW_CONNECTIONS':
            state = {
                ...state,
                connects: action.connects,
            }
            break;
        case 'SET_INVITATIONS':
            state = {
                ...state,
                invites: action.invites,
            }
            break;
        case 'SET_MESSAGES':
            let temp_mess = action.messages
            state = {
                ...state,
                messages: temp_mess,
            }

            break;
    }

    return state;
};

export default GenralReducer;