import React from 'react'
import classnames from 'classnames'

/*
position => right, left
state => boolean
float => boolean default false  
content => component default <></>
*/

function Sidebar(props) {
    return (
        props.state ?
            <div
                className={classnames(
                    "sidebar",
                    {
                        "sidebar-float": props.float === true,
                        "right-sidebar": props.position === 'right',
                        "left-sidebar": props.position === 'left',
                    }
                )}
            >
                {props.children}
            </div>
            :
            <></>
    )
}

export default Sidebar