import React from "react";

export default function ProgressBar(props) {
  // function Variant(value) {
  //         if(value < 50) return "bg-danger"
  //         else if (value < 70 && value >= 50) return "bg-warning"
  //         else if(value >= 70) return 'bg-success'
  //     }
  return (
    <div className="progress mb-3" style={{ height: props.height ? props.height : 10 }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: props.value + "%",
          backgroundColor: props.color,
        }}
        aria-valuenow={props.value}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
}
