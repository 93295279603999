import React from "react"
import { API, getLinks, debounce } from "../index"
import { useHistory } from "react-router-dom"
import { Button } from "../index"
import companyList from '../../ir_pages.json'

export default function SearchTry() {
  let history = useHistory()
  const links = getLinks()
  const [inputText, setInput] = React.useState("")
  const [options, setOptions] = React.useState([
    { label: "No Results", value: "No Results" },
  ]);
  const [cachedOptions, setCachedOptions] = React.useState({});
  const [optionLists, setOptionsList] = React.useState({});

  React.useEffect(() => {
    if (inputText.length > 2) {
      if (!(inputText in cachedOptions)) {

        const searchTermUpperCase = inputText.toUpperCase()

        const results = companyList.filter(company =>
                (company.symbol.indexOf(searchTermUpperCase) >= 0 ||
                company.name.toUpperCase().indexOf(searchTermUpperCase) >= 0 ))

                let temp = [];
                let tempDict = { ...cachedOptions };

                results.forEach((each) => {
                  temp.push({ label: each.name, value: each.symbol });
                })
                if (results.length === 0) {
                  temp.push({ label: "No Results", value: "no_option" });
                }
                setOptions(temp);
                tempDict[inputText] = temp;
                setCachedOptions(tempDict);
                let optionList = JSON.parse(JSON.stringify(optionLists));
                optionList[inputText] = results;
                setOptionsList(optionList);

        //         API({
        //   callurl: links.api_company_search,
        //   callmethod: "POST",
        //   urlparams: {},
        //   bodydata: { company_name: inputText },
        //   isfile: false,
        //   callback: (res) => {
        //     if (res.status === 1) {
        //       let temp = [];
        //       let tempDict = { ...cachedOptions };
        //       res.data.forEach((each) => {
        //         temp.push({ label: each.company_name, value: each.company_id });
        //       });
        //       if (temp.length === 0) {
        //         temp.push({ label: "No Options", value: "no_option" });
        //       }
        //       setOptions(temp);
        //       tempDict[inputText] = temp;
        //       setCachedOptions(tempDict);
        //       let optionList = JSON.parse(JSON.stringify(optionLists));
        //       optionList[inputText] = res.data;
        //       setOptionsList(optionList);
        //     }
        //   },
        // });
      } else if (inputText in cachedOptions) {
        setOptions(cachedOptions[inputText]);
      }
    } else {
      setOptions([{ label: "No Results", value: "no_option" }]);
    }
  }, [inputText]);

  function Submit(e) {
    e.preventDefault();
    if (inputText.length > 0) {
      if (options.length == 1 && options[0].value !== "no_option") {
        
        let company = companyList.filter(company => company.symbol === options[0].value)
        
        if (company[0]) {
          
          if(company[0].proxy) {
            document.getElementById('iframeInvestorRelations').src = `http://localhost:8080/?q=${company[0].symbol}`
          } else {
            document.getElementById('iframeInvestorRelations').src = company[0].url
          }
          document.getElementById('reutersIframe').src =
          'https://www.reuters.com/companies/' + options[0].value + '.AX'
          document.getElementById('reutersTitle').innerHTML = company[0].name
        }
        setInput("")
      }
    }

    // document.getElementById("dropDownMenu").style.display = "none";
  }

  function setTopbarSearchText() {
    setInput(document.getElementById("topBarSearchInput").value)
  }

  return (
    <div className="d-flex align-items-center">
      <form onSubmit={(e) => Submit(e)} autoComplete="off">
        <div id="topbar-serach-dropdown" className="dropdown">
          <input
            size="100"
            id="topBarSearchInput"
            type="text"
            className="form-control h-50"
            placeholder="Search"
            onChange={(e) => {
              debounce(setTopbarSearchText, 500);
            }}
            onFocus={() => {
              document.getElementById("searchDropDown").classList.add("show");
            }}
            onBlur={() => {
              setTimeout(() => {
                document
                  .getElementById("searchDropDown")
                  .classList.remove("show")
              }, 250);
            }}
          />
          <ul className="dropdown-menu w-100" id="searchDropDown">
            {options.map((eachOption, index) => {
              return (
                <li>
                  <button
                    type="button"
                    key={eachOption.label.toLowerCase() + index}
                    className="dropdown-item"
                    onClick={(e) => {
                      document.getElementById("topBarSearchInput").value = "";

                      let company = companyList.filter(company => company.symbol === eachOption.value)

                      if (company[0]) {
      
                          if(company[0].proxy) {
                            document.getElementById('iframeInvestorRelations').src = `http://localhost:8080/?q=${company[0].symbol}`
                          } else {
                            document.getElementById('iframeInvestorRelations').src = company[0].url
                          }
                          document.getElementById('reutersIframe').src =
                                  'https://www.reuters.com/companies/' + eachOption.value + '.AX'
                          document.getElementById('reutersTitle').innerHTML = company[0].name
                      }
                      setInput("")
                    }}
                  >
                    {eachOption.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </form>
      <Button
        color="none"
        variant="outline"
        className="px-0 ms-0"
        size="lg"
        text={<i style={{ fontSize: 22 }} className="bi bi-search ms-1" />}
        onClick={Submit}
      />
    </div>
  );
}
