import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  set_connections,
  set_invitations,
  set_messages,
  set_userData,
} from "../redux/dispatch/dispatch";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  // firebase_auth,
  // chat_collection,
  API,
  getLinks,
  TopBar,
  BottomBar,
  PageHead,
} from "../common/index";
import getRoutes from "../routes/routes";

const MINUTE_MS = 120000;
// const feather = require("feather-icons");
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function ProtectedWebPage(props) {

  console.log('Protected')

  useEffect(() => {
    document.querySelector("body").style.background = "rgba(245,245,245,0.5)";
  }, []);

  const links = getLinks();
  useEffect(() => {
    window.Waves.init();
    // feather.replace({ class: "align-self-center" });
  }, [props.connects]);

  function pagehead() {

    console.log('pageHead')
    let x = PageHead[props.location.pathname];

    if (x && ![undefined, null, ""].includes(x.title))
      document.title = x.title + " | " + props.brandname;
    else document.title = props.brandname;

		if (x && ![undefined, null, ''].includes(x.pageTitle)) {
			return (
				<>
				</>
				// <div className="row">
				// 	<div className="col-sm-12">
				// 		<div className="page-title-box">
				// 			<div className="row">
				// 				<div className="col">
				// 					<h4 className="page-title">{x.title}</h4>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</div>
				// </div>
			)
		} else {
			return (<div className='mt-2' />)
		}

  }

  return (
    <>
      <TopBar />
      <div className="page-content h-100">
        <div className="container-fluid p-2 p-md-4">
          {/* {pagehead()} */}

          <React.Suspense fallback={loading}>
            <Switch>
              {getRoutes().map((route, idx) => {
                return (
                  route.component && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => (
                        <route.component {...props} {...route.props} />
                      )}
                    />
                  )
                );
              })}
            </Switch>
          </React.Suspense>
        </div>
        <BottomBar />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.greduce.token,
    profile: state.greduce.profile,
    brandname: state.greduce.brandname,
    connects: state.greduce.connects,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    set_connections: (connections) => {
      dispatch(set_connections(connections));
    },
    set_messages: (messages) => {
      dispatch(set_messages(messages));
    },
    set_invitations: (invites) => {
      dispatch(set_invitations(invites));
    },
    set_userData: (options) => {
      dispatch(set_userData(options));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedWebPage);