import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
import { SideBarMenu } from "./menu";

function BottomBar(props) {
  const location = useLocation();
  return (
    <div id="bottombar-nav" className="fixed-bottom border-top d-md-none">
      <div className="d-flex h-100">
        {Object.values(SideBarMenu()).map((item, i) => {
          if (item.mobile)
            return (
              <div className="w-25">
                <NavLink
                  to={item.to}
                  className={classnames(
                    "d-flex flex-column align-items-center text-decoration-none h-100",
                    {
                      "text-dark fw-bold": location.pathname === item.to,
                      "text-muted hover:text-gray-700":
                        location.pathname !== item.to,
                    }
                  )}
                >
                  <i className={"font-22 flex-grow-1 bi bi-" + item.icon} />
                  <span className="font-12"> {item.name} </span>
                </NavLink>
              </div>
            );
          else return <></>;
        })}
      </div>
    </div>
  );
}

export default BottomBar;
