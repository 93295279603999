import Button from './component/Button'
import Card from './component/Card'
import Elements from './component/Elements'
import Modal from './component/Modal'
import Sidebar from './component/Sidebar'
import Tab from './component/Tab'
import Widget from './component/Widget'
import Table from './component/Table'
import Carousel from './component/Carousel'
import Popover from './component/tippy'
import Pagination from "./component/Pagination"

import API from './helper/api'
import getLinks from './helper/links'
import { initialGenerator, showAlertMessage, debounce } from './helper/function'
// import { firebase_auth, firebase_db, chat_collection, update_firebase_doc } from './helper/firebase'
import { dateLabel } from "./helper/dateLabel"

import TopBar from './layout/topbar'
import BottomBar from './layout/bottombar'
import Footer from './layout/footer'
import { SideBarMenu, PageHead } from './layout/menu'
import Badge from './component/Badge'
import ProgressBar from './component/ProgressBar'

export {
    Button,
    Card,
    Elements,
    Modal,
    Sidebar,
    Tab,
    Widget,
    Badge,
    Table,
    Carousel,
    Popover,
    Pagination,
    ProgressBar,

    API,
    getLinks,
    dateLabel,
    initialGenerator,
    showAlertMessage,
    debounce,
    // firebase_auth,
    // firebase_db,
    // chat_collection,
    // update_firebase_doc,


    TopBar,
    BottomBar,
    Footer,
    SideBarMenu,
    PageHead,
}