import env from '../../env.json'

const dev = {
    home_page: '/index.html',
    trade_event: '/trade-events.html',
    about_us: '/about.html',
    termsofuse: '/terms-and-conditions.html',
    privacypolicy: '/privacy-policy.html',
    api_register: '/signup',
    api_contactus: '/contactus',
    api_login: '/login',
    api_save_uuid: '/saveuuid',
    api_logout: '/logout',
    api_forgot_password: '/forgotpass',
    chat_connections: '/connections',
    invitations: '/invitations',
    save_connections: '/save_connection',
    api_bookmark: '/bookmark',
    api_all_recent_news: '/allrecentnews',
    api_get_profile: '/profile',
    api_user_details: '/userdetails',
    api_usermgmt_update: '/updatesubuser',
    api_usermgmt_add: '/addsubuser',
    api_usermgmt_delete: '/deletesubuser',
    api_usermgmt_list: '/userlist',
    api_company_search: '/companysearch',
    api_product_search: '/productsearch',
    api_company_news: '/recentnews',
    api_company_update: '/companyupdate',
    api_industry_updates: '/industrynews',
    api_profile_status: '/profile_status',
    product_requirement: '/productrequirement',
    verify_token: '/verifyAuthToken',
    refresh_firebase_token: '/refreshtoken'
}

const prod = {
    home_page: '/index.html',
    trade_event: '/trade-events.html',
    about_us: '/about.html',
    termsofuse: '/terms-and-conditions.html',
    privacypolicy: '/privacy-policy.html',
    api_register: '/signup',
    api_contactus: '/contactus',
    api_login: '/login',
    api_save_uuid: '/saveuuid',
    api_logout: '/logout',
    api_forgot_password: '/forgotpass',
    api_get_profile: '/profile',
    api_bookmark: '/bookmark',
    user_details: '/userdetails',
    api_usermgmt_list: '/userlist',
    api_usermgmt_update: '/updatesubuser',
    api_usermgmt_add: '/addsubuser',
    api_usermgmt_delete: '/deletesubuser',
    api_company_search: '/companysearch',
    api_all_recent_news: '/allrecentnews',
    api_profile_status: '/profile_status',
}

function getLinks() {
    if (env.env === 'production') return prod
    return dev
}

export default getLinks