import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

// import Auth from '../pages/auth/main'
// import Contact from '../pages/auth/component/contact'

function UnProtectedWebPage(props) {
    console.log('UnProtected')
    return (
        <Switch>
            {/* <Route path='/contact/'> <Contact brandname={props.brandname} /> </Route> */}
            {/* <Route path="/" component={Auth} /> */}
        </Switch>
    )
}

const mapStateToProps = (state) => {
    return {
        brandname: state.greduce.brandname
    }
}

export default connect(mapStateToProps, null)(UnProtectedWebPage)