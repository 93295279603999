/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import home_logo from "../../pages/auth/img/stockcomms_logo_white.jpeg";
import { NavLink, useLocation } from "react-router-dom";
import { SideBarMenu, initialGenerator } from "../index";
import { update_primary_bar } from "../../redux/dispatch/dispatch";
import SearchTry from "./CustomSearch";

function TopBar(props) {
  const [notificationList, setList] = useState({ invites: [], messages: [] });
  const location = useLocation();

  return (
    <nav id="topbar-nav" className="navbar fixed-top navbar-light py-0">
      <div className="d-flex flex-grow-1 px-2 px-md-4 align-items-center h-100">
        <NavLink
          to={"/app/home"}
          className="navbar-brand py-0"
          style={{ width: "280px" }}
        >
          <img
            src={home_logo}
            className="img-fluid rounded-0 w-100"
            alt="Logo"
          />
        </NavLink>

        <div className="d-flex flex-grow-1 h-100">
          <div className="d-none d-md-flex ms-4 h-100">
            {Object.values(SideBarMenu()).map((item, i) => (
              <NavLink
                to={item.to}
                className={classnames(
                  "d-flex flex-column align-items-center me-3 text-decoration-none h-100",
                  {
                    "text-dark fw-bold": location.pathname === item.to,
                    "text-muted hover:text-gray-700":
                      location.pathname !== item.to,
                  }
                )}
              >
                <i className={"flex-grow-1 font-22 bi bi-" + item.icon} />
                <span> {item.name} </span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.greduce.profile,
    sidebarShow: state.greduce.sidebarShow,
    messages: state.greduce.messages,
    invites: state.greduce.invites,
    connections: state.greduce.connections,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    update_primary_bar: (state) => {
      dispatch(update_primary_bar(state));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
