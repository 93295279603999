import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import ProtectedWebPage from './protected-router'
import UnProtectedWebPage from './unprotected-router'

function WebPage(props) {
	console.log('WebPage')
	return (
		<Router>
			<Switch>
  	  		    <Route path='/app/' component={ProtectedWebPage} />
				<Route exact path='/' render={() => (<Redirect to='/app/home' />)} />  
				{/* <Route path='/' component={UnProtectedWebPage} /> */}
			</Switch>
		</Router>
	)
}

export default WebPage;