function SideBarMenu() {
    let menu = {}
    menu['home'] = { name: 'Home', icon: 'house', to: '/app/home', mobile: true }
    menu['ir'] = { name: 'Investor Relations', icon: 'info-square', to: '/app/ir', mobile: true }
    menu['bizsquiz'] = { name: 'Biz Squiz', icon: 'newspaper', to: '/app/biz_squiz', mobile: true }
    menu['featured'] = { name: 'Featured', icon: 'star', to: '/app/featured', mobile: true }
    menu['contactus'] = { name: 'Contact Us', icon: 'telephone', to: '/app/contact_us', mobile: true }
    return menu
}

const PageHead = {
    '/app/ir': { pageTitle: 'Investor Relations', title: 'Users', breadcrumb: ['users'] },
    '/app/biz_squiz': { title: 'Biz Squiz', breadcrumb: ['bizsquiz'] },
    '/app/home': { title: 'Home', breadcrumb: ['home'] },
    '/app/featured': { title: 'Featured', breadcrumb: ['featured'] },
    '/app/contact_us': { title: 'Contact Us', breadcrumb: ['contactus'] },
}

export {
    SideBarMenu,
    PageHead
}
