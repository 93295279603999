const migrations = {
    0: (state) => {
        let temp = { ...state }
        temp.greduce["token"] = {}
        return temp
    },
    1: (state) => {
        let temp = { ...state }
        temp.greduce["connections"] = {}
        return temp
    },
    2: (state) => {
        let temp = { ...state }
        temp.greduce["unreadmessage"] = {}
        return temp
    },
    3: (state) => {
        let temp = { ...state }
        delete temp.greduce["unreadmessage"]
        return temp
    },
    4: (state) => {
        let temp = { ...state }
        temp.greduce["messages"] = {}
        return temp
    }
}

export {
    migrations
}