import axios from 'axios'
import store from '../../redux/store'

async function API(entity) {
    const reduxState = store.getState()

    const data = (entity) => {
        if (entity.callmethod !== 'GET') {
            if (entity.isfile) {
                return entity.bodydata
            } else {
                return JSON.stringify(entity["bodydata"])
            }
        } else {
            return null
        }
    }

    // const progress = (progressPercent, entity) => {
    //     if (entity.isfile) {
    //         return entity.uploadprogress(progressPercent)
    //     }
    // }
    const Headers = {}
    if (reduxState.greduce.token.access_token && entity.send_token !== true)
        Headers['Authorization'] = `Bearer ${reduxState.greduce.token.access_token}`
    else if (entity.access_token && entity.send_token !== true)
        Headers['Authorization'] = `Bearer ${entity.access_token}`
    Headers["content-type"] = entity.contentType ? entity.contentType : 'application/json'

    return axios({
        baseURL: entity.callurl,
        method: entity.callmethod,
        params: { ...entity.urlparams },
        data: data(entity),
        // onUploadProgress: (progressEvent) => progress(Math.round((progressEvent.loaded * 100) / progressEvent.total), entity),
        headers: Headers
    }).then(res => {
        // console.log(res,res.data.status !== undefined && window.location.pathname !== '/app/logout')
        if (res.data.status !== undefined && window.location.pathname !== '/app/logout') {
            if (res.data.status === 0 && res.data.logoutRequired) {
                window.localStorage.clear()
                localStorage.setItem("errorMessage", res.data.errors);
                window.location.href = "/"
            }
            return entity.callback(res.data)
        } else {
            return entity.callback(res.data)
        }
    })
}

export default API