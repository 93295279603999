// general FUNCTIONS
export function update_primary_bar(new_state) {
    return {
        type: 'NAVBAR_SET',
        sidebarShow: new_state
    }
}

export function set_userData(new_state) {
    return {
        type: 'SET_USERDATA',
        data: new_state
    }
}

export function set_searchCompany(new_state) {
    return {
        type: "SET_SEARCHCOMPANY",
        searchValue: new_state
    }

}

export function set_connections(connections) {
    return {
        type: 'SET_CONNECTIONS',
        connections: connections
    }
}

export function set_new_connections(connects) {
    return {
        type: 'SET_NEW_CONNECTIONS',
        connects: connects
    }
}

export function set_invitations(invites) {
    return {
        type: 'SET_INVITATIONS',
        invites: invites
    }
}

export function set_messages(messages) {
    let returnObj = { type: 'SET_MESSAGES', messages: messages }

    return returnObj
}