import moment from "moment";

export function initialGenerator(text = "") {
  if (text === "") return "";
  if (text.length <= 2) return text.toUpperCase();
  else {
    let temp = text.split(" "),
      temps = "",
      i = 1;
    if (temp.length > 1) {
      while (i <= 2) {
        temps = temps + temp[i - 1].charAt(0);
        i++;
      }
      return temps.toUpperCase();
    } else {
      return text.substring(0, 2).toUpperCase();
    }
  }
}

export function showAlertMessage(
  message = "",
  type = "primary",
  time = 5000,
  containerID = "status-message"
) {
  let element = document.getElementById(containerID);
  if (![null, undefined, ""].includes(element)) {
    element.innerHTML =
      "<div class='alert mb-0 rounded-0 alert-" +
      type +
      "'><strong>" +
      message +
      "</strong></div>";
    setTimeout(() => {
      if (element) element.innerHTML = "";
    }, time);
  }
}

export function StringTruncate(file) {
  if (file.length > 20) {
    let nameArray = file.split(".");
    let fileType = `.${nameArray.pop()}`;
    let fileName = nameArray.join(" ");

    if (fileName.length >= 20) {
      fileName = fileName.substr(0, 20) + "...";
    }

    return fileName + fileType;
  } else return file;
}

export function LogoGenerator(name) {
  let extension = name.split(".").pop();
  switch (extension) {
    case "doc":
    case "docx":
      return "/doc_icon.png";
    case "png":
    case "gif":
    case "tif":
    case "bmp":
    case "jpg":
    case "jpeg":
      return "/image_icon.png";
    case "pps":
    case "ppsx":
      return "/pps_icon.png";
    case "ppt":
    case "pptx":
      return "/ppt_icon.png";
    case "pdf":
      return "/pdf_icon.png";
    case "txt":
      return "/txt_icon.png";
    case "zip":
      return "/zip_icon.png";
    case "xls":
    case "csv":
    case "xlsx":
      return "/xls_icon.png";
    default:
      return "/default_icon.png";
  }
}

export function FromNow(stringDate) {
  let localtimeUTC = moment.utc(stringDate, "YYYY-MM-DD HH:mm:ss.s").toDate();
  let localtime = moment(localtimeUTC).format("YYYY-MM-DD HH:mm:ss.s");
  return moment(localtime).fromNow();
}

export function chatTime(timeStamp, modifier) {
  var lastWeekTimestamp = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
  var todaysTimeLimit = new Date().setHours(0, 0, 0, 0);
  if (modifier === 0) {
    if (timeStamp > todaysTimeLimit) {
      return moment(timeStamp).format("hh.mm a");
    } else if (timeStamp < lastWeekTimestamp) {
      return moment(timeStamp).format("DD/MM/YYYY");
    } else {
      return moment(timeStamp).format("dddd");
    }
  } else {
    return moment(timeStamp).format("hh.mm a");
  }
}

export function debounce(callback, time=200) { 
	window.clearTimeout(window.debounceTimer);
  window.debounceTimer = window.setTimeout(callback, time);
}
