import React from 'react';

function getRoutes() {
  // const Logout = React.lazy(() => import('../common/component/logout'))
  const Home = React.lazy(() => import('../pages/home/main'))
  const ContactUs = React.lazy(() => import('../pages/contact_us/main'))
  const Featured = React.lazy(() => import('../pages/featured/main'))
  const BizSquiz = React.lazy(() => import('../pages/biz_squiz/main'))
  const InvestorPortal = React.lazy(() => import('../pages/investor_portal/main'))

  // const User = React.lazy(() => import('../pages/user/main'))
  // const Home = React.lazy(() => import('../pages/home/main'))
  // const Profile = React.lazy(() => import('../pages/profile/main'))
  // const Chat = React.lazy(() => import('../pages/chat/main'))
  // const Search = React.lazy(() => import('../pages/advancedsearch/main'))
  // const ProductRequirements = React.lazy(() => import('../pages/productrequirements/main'))
  // const CompanySearch = React.lazy(() => import('../pages/advancedsearch/companysearch'))
  // const ProductSearch = React.lazy(() => import('../pages/advancedsearch/productsearch'))

  let routes = [

    { path: '/app/ir', name: 'Investor Portal', component: InvestorPortal },
    // { path: '/app/logout', name: 'Logout', component: Logout },
    { path: '/app/home', name: 'Home', component: Home },
    { path: '/app/contact_us', name: 'Contact Us', component: ContactUs },
    { path: '/app/featured', name: 'Featured', component: Featured },
    { path: '/app/biz_squiz', name: 'Biz Squiz', component: BizSquiz }

    // { path: '/app/logout', name: 'Logout', component: Logout },
    // { path: '/app/user', name: 'User', component: User },
    // { path: '/app/home', name: 'Home', component: Home },
    // { path: '/app/profile/:company_id', name: 'Profile', component: Profile },
    // { path: '/app/profile', name: 'Profile', component: Profile },
    // { path: '/app/chat', name: 'Chat', component: Chat },
    // { path: '/app/advanced_search', name: 'Advanced Search', component: Search },
    // { path: '/app/product_requirements', name: 'Product Requirements', component: ProductRequirements },

    // { path: '/app/company_search', name: 'Company Search', component: CompanySearch },
    // { path: '/app/product_search', name: 'Product Search', component: ProductSearch }
  ]

  return routes
}

export default getRoutes