import React from "react"
import classnames from 'classnames'
import Select from 'react-select'

function Elements(props) {

  function formElement(elementObj) {
    // eslint-disable-next-line default-case
    switch (elementObj.type) {
      case "email":
      case "password":
      case "date":
      case "text":
      case "time":
      case "tel":
      case "datetime-local":
        return (
          <div key={elementObj.id} className={elementObj.className !== undefined ? "form-group " + (elementObj.className) : 'form-group'}>
            {elementObj.label !== undefined ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            <input
              type={elementObj.type}
              style={{ height: "38px" }}
              id={elementObj.id}
              name={elementObj.id}
              placeholder={elementObj.placeholder}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              value={elementObj.value}
              onChange={(e) => elementObj.onchange(e.target.value, elementObj.id)}
              autoComplete="off"
              autoFocus={elementObj.autoFocus ? elementObj.autoFocus : null}
              readOnly={elementObj.readOnly ? elementObj.readOnly : null}
              minLength={elementObj.minLength ? elementObj.minLength : null}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              pattern={elementObj.pattern ? elementObj.pattern : null}
              form={elementObj.form !== undefined ? (elementObj.form) : null}
              className={classnames(
                "form-control",
                {
                  "form-control-sm": elementObj.size === 'sm',
                  "form-control-lg": elementObj.size === 'lg',
                }
              )}
            />
          </div>
        )
      case "number":
        return (
          <div key={elementObj.id} className={elementObj.className !== undefined ? "form-group " + (elementObj.className) : 'form-group'}>
            {elementObj.label ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            <input
              type={elementObj.type}
              id={elementObj.id}
              name={elementObj.id}
              placeholder={elementObj.placeholder}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              value={elementObj.value}
              onChange={(e) => elementObj.onchange(e.target.value, elementObj.id)}
              autoComplete="off"
              autoFocus={elementObj.autoFocus ? elementObj.autoFocus : null}
              min={elementObj.min !== undefined ? elementObj.min : null}
              max={elementObj.max !== undefined ? elementObj.max : null}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              form={elementObj.form !== undefined ? (elementObj.form) : null}
              className={classnames(
                "form-control",
                {
                  "form-control-sm": elementObj.size === 'sm',
                  "form-control-lg": elementObj.size === 'lg',
                }
              )}
            />
          </div>
        )
      case "textarea":
        return (
          <div key={elementObj.id} className="form-group">
            {elementObj.label ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            <textarea
              id={elementObj.id}
              maxLength={5000}
              name={elementObj.id}
              placeholder={elementObj.placeholder}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              value={elementObj.value}
              autoFocus={elementObj.autoFocus ? elementObj.autoFocus : null}
              onChange={(e) => elementObj.onchange(e.target.value, elementObj.id)}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              form={elementObj.form !== undefined ? (elementObj.form) : null}
              autoComplete="off"
              rows={3}
              className={classnames(
                "form-control",
                {
                  "form-control-sm": elementObj.size === 'sm',
                  "form-control-lg": elementObj.size === 'lg',
                }
              )}
            />
          </div>
        )
      case "select":
        return (
          <div key={elementObj.id} className="form-group">
            {elementObj.label !== undefined ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            <div className="xs-12 mb-9"></div>
          </div>
        )
      case "file":
        return (
          <div key={elementObj.key}>
            {elementObj.label !== undefined ? (<label className="w-100 text-left">{elementObj.label}</label>) : null}
            <input
              type="file"
              id={elementObj.id}
              // name={elementObj.id}
              className={elementObj.className !== undefined ? "dropify " + elementObj.className : "dropify"}
              onChange={(e) => elementObj.onchange(e.target.files, elementObj.id)}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              // data-allowed-file-extensions={elementObj['data-allowed-file-extensions'] !== undefined ? (elementObj['data-allowed-file-extensions']) : false}
              accept={elementObj.accept !== undefined ? elementObj.accept : toString(false)}
              multiple
            />

          </div>
        )
      case 'react_select':
        return (
          <div className={elementObj.className !== undefined ? "form-group w-100 " + (elementObj.className) : ('form-group w-100')} key={elementObj.id}>
            {elementObj.label !== undefined ? (<label className="w-100 text-left">{elementObj.label}</label>) : null}
            <Select
              id={elementObj.id}
              value={elementObj.value}
              isSearchable={elementObj.isSearchable !== undefined ? (elementObj.isSearchable) : true}
              options={elementObj.options}
              className="w-100"
              isMulti={elementObj.isMulti !== undefined ? elementObj.isMulti : false}
              onChange={(val) => elementObj.onchange(val, elementObj.id)}
              getOptionLabel={elementObj.labelKey ? (option) => option[elementObj.labelKey] : (option) => option.label}
              getOptionValue={elementObj.valueKey ? (option) => option[elementObj.valueKey] : (option) => option.value}
              placeholder={elementObj.placeholder !== undefined ? (elementObj.placeholder) : ('')}
              isDisabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              isLoading={elementObj.loading !== undefined ? (elementObj.loading) : false}
              defaultValue={elementObj.defaultValue !== undefined ? elementObj.defaultValue : ''}
              styles={elementObj.styles ? {
                ...elementObj.styles, menuList: (provided, state) => ({ ...provided, position: 'absolute', background: 'white', width: document.getElementById(elementObj.id).offsetWidth })
              } : {
                menuList: (provided, state) => ({ ...provided, position: 'absolute', background: 'white', width: document.getElementById(elementObj.id).offsetWidth })
              }}
            />
          </div>
        )
      case 'radio':
        return (
          <React.Fragment key={elementObj.name}>
            {elementObj.label ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            {
              elementObj.options.map(item => (
                <div
                  key={elementObj.name + '_' + item.label}
                  className={'custom-control custom-radio ' + elementObj.className}
                >
                  <input
                    key={elementObj.name + '_' + item.label}
                    type={elementObj.type}
                    id={elementObj.name + '_' + item.label}
                    name={elementObj.name}
                    disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
                    checked={elementObj.value === item.value}
                    onChange={(e) => { elementObj.onchange(item.value, elementObj.name) }}
                    className={elementObj.size !== undefined ? "custom-control-input " + (elementObj.size) : "custom-control-input md"}
                  />
                  {item.label !== undefined ? (<label className="custom-control-label" htmlFor={elementObj.name + '_' + item.label}>{item.label}</label>) : null}
                </div>

              ))
            }
          </React.Fragment>
        )
      case 'checkbox':
        return (
          <div key={elementObj.id} className={elementObj.className !== undefined ? "custom-control custom-checkbox " + (elementObj.className) : 'custom-control custom-checkbox'}>
            <input
              type={elementObj.type}
              id={elementObj.id}
              name={elementObj.id}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              defaultChecked={elementObj.value}
              checked={elementObj.value}
              onChange={(e) => { elementObj.onchange(!elementObj.value, elementObj.id) }}
              form={elementObj.form !== undefined ? (elementObj.form) : null}
              className={elementObj.size !== undefined ? "custom-control-input " + (elementObj.size) : "custom-control-input"}
            />
            {elementObj.label !== undefined ? (<label className="custom-control-label" htmlFor={elementObj.id}>{elementObj.label}</label>) : null}
          </div>
        )
      case 'switch':
        return (
          <div key={elementObj.id} className="custom-control custom-switch">
            <input
              id={elementObj.id}
              type="checkbox"
              checked={elementObj.value}
              disabled={elementObj.disabled !== undefined ? (elementObj.disabled) : false}
              className="custom-control-input"
              onChange={() => { elementObj.onchange(!elementObj.value, elementObj.id) }}
            />
            {elementObj.label !== undefined ? (
              <label className="custom-control-label" htmlFor={elementObj.id}>
                {elementObj.label}
              </label>
            ) : null}
          </div>
        )
      case "dropify_upload":
        return (
          <React.Fragment key={elementObj.id}>
            {elementObj.label !== undefined ? (<label htmlFor={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>) : null}
            <input
              type="file"
              className={elementObj.className !== undefined ? "dropify " + (elementObj.className) : "dropify"}
              data-height="300"
              disabled={elementObj.disabled ? elementObj.disabled : false}
              data-allowed-file-extensions={elementObj.fileType ? elementObj.fileType : ['*']}
              data-max-file-size-preview=
              {elementObj.fileSize ? elementObj.fileSize : "10M"}
              required={elementObj.requiredFlag ? elementObj.requiredFlag : false}
              id={elementObj.id}
            />
          </React.Fragment>
        )
      case 'label':
        return (
          <label key={elementObj.id} className="w-100 text-left" >{elementObj.label}</label>
        )
    }
  }

  return (
    <>
      {/* <CForm name="askDataForm" className="form-horizontal w-100" > */}
      {
        (props.formField).map(field => {
          let v = field.visible !== undefined ? (field.visible) : true
          if (v) return formElement(field)
        })
      }
      {/* </CForm> */}
    </>
  )
}

export default Elements