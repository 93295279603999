import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'


function Footer(props) {
    return (
        <footer className="footer text-center text-sm-left">
            &copy; {moment().format('Y')} {props.brandname}
        </footer>
    )
}

const mapStateToProps = (state) => {
    return {
        brandname: state.greduce.brandname,
    }
}

export default connect(mapStateToProps, null)(Footer)