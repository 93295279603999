import React from 'react'
import ReactPaginate from 'react-paginate';

export default function Pagination(props) {
    return (
        <>
            <ReactPaginate
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                pageCount={props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={props.onPageClick}
                initialPage={props.initialPage}
            />
        </>
    )
}
