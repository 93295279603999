import React from 'react'
import Tippy from '@tippyjs/react'

function Popover(props) {

    return (
        <Tippy
            arrow={props.arrow !== undefined ? props.arrow : false}
            placement={props.placement !== undefined ? props.placement : "right"}
            theme="light-border"
            interactive={true}
            trigger="click"
            maxWidth={200}
            content={
                <div className="p-2" style={{ minWidth: "150px" }}>
                    {props.content}
                </div>
            }
        >
            {props.button}
        </Tippy>
    )
}
export default Popover