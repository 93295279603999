/*
PROPS:
text Required
onBadgeClick Optional
color [primary, secondary, success, danger, warning, info, light, dark] default primary 
variant [soft, outline, outline-round, soft-round , round ] default normal badge
*/

import React from 'react'
import classnames from 'classnames'

function Variant(variant, color) {
    switch (variant) {
        case "outline":
            return "badge-outline" + color
        case "soft":
            return "badge-soft" + color
        case "soft-round":
            return "badge-pill badge-soft" + color
        case "outline-round":
            return "badge-pill badge-outline" + color
        case "round":
            return "badge-pill badge" + color
        default:
            return "badge" + color
    }
}

export default function Badge(props) {
    var color = {
        primary: "-primary",
        secondary: "-secondary",
        success: "-success",
        warning: "-warning",
        info: "-info",
        danger: "-danger",
        dark: "-dark",
        light: "-light",
    }

    return (
        <>
            {props.badges.map((item) => {
                return (
                    <span
                        style={{ cursor: props.onBadgeClick ? "pointer" : "default" }}
                        id={item.id}
                        key={item.id}
                        className={classnames('mb-1',
                            props.badgeClass ? "badge " + props.badgeClass : "badge",
                            Variant(
                                props.variant === undefined ? "" : props.variant,
                                props.color === undefined ? color["primary"] : color[props.color],
                            )
                        )}
                        onClick={
                            props.onBadgeClick
                                ? () => {
                                    props.onBadgeClick(item.id)
                                }
                                : () => { }
                        }
                    >{props.labelKey ? item[props.labelKey] : item.text}</span>
                )
            })}
        </>
    )
}
