/*
PROPS:
    array of objects eg[{id, title, text, description, icon, alignIcon, type Required}]
*/

import React from "react"
import classnames from 'classnames'

function Widget(props) {

  function formElement(elementObj) {
    switch (elementObj.type) {
      case "info":
        return (
          <div
            id={elementObj.id}
            key={elementObj.id}
            className="card report-card"
          >
            <div className="card-body">
              <div className={classnames(
                "row d-flex justify-content-center",
                {
                  "flex-row-reverse": elementObj.alignIcon === 'left'
                }
              )}>
                <div className="col">
                  {elementObj.title !== undefined && <p className="text-dark mb-0 font-weight-semibold">{elementObj.title}</p>}
                  {elementObj.text !== undefined && <h3 className="m-0">{elementObj.text}</h3>}
                  {elementObj.description !== undefined && <p className="mb-0 text-truncate text-muted">{elementObj.description}</p>}
                </div>
                {elementObj.icon !== undefined && <div className="col-auto align-self-center">
                  <div className="report-main-icon bg-light-alt">
                    {elementObj.icon}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        )
    }
  }


  return (
    <>
      {props.values.map((field) => {
        return formElement(field)
      })}
    </>
  )
}

export default Widget