import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import WebPage from './routes/Router'
import { Provider } from 'react-redux'
import store from './redux/store'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

// CSS
import 'bootstrap-icons/font/bootstrap-icons.css'
import "dropify/dist/css/dropify.min.css"
import 'react-tabulator/css/bulma/tabulator_bulma.min.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import "owl.carousel/dist/assets/owl.theme.default.css"
import 'tippy.js/themes/light-border.css'
import './common/scss/app.scss'

// SCRIPTS 
import 'node-waves/dist/waves.min.js'
import "dropify/dist/js/dropify.js"
// import 'owl.carousel/dist/owl.carousel'
import "owl.carousel/dist/owl.carousel.js"
// import 'owl.carousel'

let persistor = persistStore(store)
window.debounceTimer = null

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <WebPage />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)