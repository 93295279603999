/*
PROPS:
iamges Required
loop default false
mouseDrag default false
nav default false
dots default false
autoplay default false
items no of images should be seen
*/

import React from 'react'
import OwlCarousel from 'react-owl-carousel'

function Carousel(props) {
    function backStyle(url) {
        return ({
            backgroundImage: `url("${url}")`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative'
        })
    }

    return (
        <>
            <OwlCarousel
                items={props.items}
                loop={props.loop !== undefined ? props.loop : true}
                mouseDrag={props.mouseDrag !== undefined ? props.mouseDrag : true}
                nav={props.nav !== undefined ? props.nav : true}
                autoplay={props.autoplay !== undefined ? props.autoplay : true}
                margin={20}
                dots={props.dots !== undefined ? props.dots : false}
                className='owl-theme'
                stageClass="owl-stage h-100"
                stageOuterClass="owl-stage-outer h-100"
                loadedClass="owl-loaded h-100"
            >
                {
                    props.images.map((item) => {
                        return (
                            <div
                                key={item.url}
                                className="d-flex flex-column item h-100"
                            >
                                <div
                                    className='d-flex align-items-center flex-grow-1'
                                    style={props.OnBackground === true ? backStyle(item.url) : {}}
                                >
                                    {props.OnBackground === true ? <></> : <img src={item.url} alt='pic' />}
                                </div>
                                <div>
                                    {item.name !== undefined ? <h4>{item.name}</h4> : <></>}
                                </div>
                            </div>
                        )
                    })
                }
            </OwlCarousel>
        </>
    )
}

export default Carousel