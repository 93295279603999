import { createStore, combineReducers } from 'redux'
import GenralReducer from './reducer/genralreducer'

import { persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { migrations } from './migration'

const persistConfig = {
    key: 'root',
    version: 4,
    storage,
    migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, combineReducers({ greduce: GenralReducer }))
let store = createStore(persistedReducer, {})

// store.subscribe(() => {
//     if (env.env === "dev") console.log(store.getState())
// });

export default store;